<template>
    <vs-popup id="product-detail--modal" :title="title" :active="visible" @close="onClickClose" class="product-modal">
        <div id="product-detail--loader" class="vs-con-loading__container overflow-visible">
            <template v-if="product">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full font-bold">
                        <span>Artikel-Nr:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        {{product.ordernumber}}
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full font-bold">
                        <span>Artikel-Typ:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        {{product.type.name}}
                    </div>
                </div>
                <div class="vx-row mb-6 border-gray-300 border-solid border-0 border-b-2 pb-6">
                    <div class="vx-col sm:w-1/3 w-full font-bold">
                        <span>Rabattierung möglich:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <boolean-icon :value="product.discountable"></boolean-icon>
                    </div>
                </div>

                <div class="vx-row mb-6 border-gray-300 border-solid border-0 border-b-2 pb-6">
                    <div class="vx-col sm:w-1/3 w-full font-bold">
                        <span>Abnahme:</span>
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        Min Abnahme: <br>
                        {{product.min_amount}}
                    </div>
                    <div class="vx-col sm:w-1/3 w-full">
                        Schrittweiße Abnahme: <br>
                        {{product.amount_steps}}
                    </div>
                </div>

                <div class="vx-row mb-6 border-b-2 border-danger">
                    <div class="vx-col sm:w-1/4 w-full font-bold">
                        <span>Einkaufspreis:</span>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full">
                        {{product.purchase_price|currency}}
                    </div>
                    <div class="vx-col sm:w-1/4 w-full font-bold">
                        <span>MwSt:</span>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full">
                        {{product.tax|tax}}
                    </div>
                </div>
                <div class="vx-row mb-6 border-gray-300 border-solid border-0 border-b-2 pb-6">
                    <div class="vx-col sm:w-1/4 w-full font-bold">
                        <span>Preis:</span>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full">
                        {{product.price|currency}}
                    </div>
                    <div class="vx-col sm:w-1/4 w-full font-bold">
                        <span>Mindestpreis:</span>
                    </div>
                    <div class="vx-col sm:w-1/4 w-full">
                        {{product.min_price|currency}}
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full font-bold">
                        <span>Beschreibung:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full" v-html="product.description">
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full font-bold">
                        <span>Angebots-Beschreibung:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full" v-html="product.offer_description">
                    </div>
                </div>
                <div class="vx-row mb-6 border-gray-300 border-solid border-0 border-b-2 pb-6">
                    <div class="vx-col sm:w-1/3 w-full font-bold">
                        <span>Interne Beschreibung:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full" v-html="product.internal_description">
                    </div>
                </div>
                <div class="vx-row">
                    <div class="vx-col sm:w-1/3 w-full font-bold">
                        <span>Workflow:</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full html-editor-content" v-html="product.internal_workflow_description">
                    </div>
                </div>
            </template>
        </div>
    </vs-popup>
</template>

<script>
    import ApiService from "../../api";
    import BooleanIcon from "../boolean-icon/BooleanIcon";

    export default {
        name: "ProductDetailModal",
        components: {BooleanIcon},
        props: {
            productId: {
                type: Number,
                default: null
            },

            active: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                title: '',
                product: null
            }
        },

        computed: {
            visible() {
                return this.active;
            },
        },

        methods: {
            fetchProduct() {
                //this.openLoading();

                ApiService.get(`articles/${this.productId}`).then(response => {

                    if (response.status !== 200) {
                        //this.closeLoading();
                        return;
                    }

                    this.product = response.data.result;

                    this.title = this.product.name;

                    //this.closeLoading();

                });
            },

            onClickClose() {
                this.$emit('update:active', false)
            },

            onProductIdChange(id, oldId) {
                if (this.product && id === oldId) {
                    return;
                }

                if (id !== null && id !== '' && this.active) {
                    this.fetchProduct();
                }
            },

            onActiveChange(value) {
                if (value) {
                    if (this.productId !== null && this.productId !== '') {
                        this.fetchProduct();
                    }
                }
            },

            openLoading() {
                this.$vs.loading({
                    container: '#product-modal--loader',
                    scale: 0.6
                });
            },

            closeLoading() {
                this.$vs.loading.close('#product-modal--loader > .con-vs-loading');
            },
        },

        watch: {
            productId: 'onProductIdChange',
            active: 'onActiveChange'
        }
    }
</script>

<style scoped>

</style>