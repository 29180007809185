<template>
  <div class="contract-detail">

    <vs-row>
      <vs-col  vs-w="9">
        <vs-row>
          <vx-card title="Vertragspartner">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full mb-1">
                <div class="vx-row mb-2">
                  <div class="vx-col w-full mb-0">

                    <div class="vx-row mb-2">
                      <div class="vx-col  w-full mb-0">
                        <label>Kunde auswählen:</label>
                        <mg-select v-model="client_id" :options="clients" name="client"
                                   placeholder="Kunde auswählen"
                                   track-by="id" label="company_name" data-vv-as="Kunde" v-validate="'required'">
                        </mg-select>
                        <span class="text-danger text-sm" v-if="errors.has('clients')">Dieses Feld ist ein Pflichtfeld</span>
                      </div>
                    </div>

                    <ul class="centerx">
                      <li>
                        <vs-radio color="success" v-model="status" vs-value="1">Vertrag gültig
                        </vs-radio>
                      </li>
                      <li>
                        <vs-radio color="warning" v-model="status" vs-value="2">Vertrag wurde
                          gekündigt (noch aktiv)
                        </vs-radio>
                      </li>
                      <li>
                        <vs-radio color="danger" v-model="status" vs-value="3">Vertrag ist
                          ausgelaufen (inaktiv)
                        </vs-radio>
                      </li>
                    </ul>
                    <div class="mt-3">
                      <label>Vertragsnummer:</label><br>
                      <vs-input v-model="contract_number" disabled=""></vs-input>
                    </div>

                    <div class="mt-5">
                      <label>Vertrags-Datei:</label><br>
                    </div>
                    <div class="flex mt-1">

                      <vs-button color="warning" type="border" class="mr-3 mb-auto" size="small"
                                 target="_blank"
                                 :href="`${$globalUrl}storage/contract_files/${contract_file}`"
                                 v-if="contract_file">
                        Vertrag einsehen
                      </vs-button>
                      <label for="contractUpload">
                        <feather-icon icon="UploadIcon" class="inline-block mr-2"
                                      svgClasses="w-8 h-8"/>
                      </label>
                      <input type="file" id="contractUpload"
                             @input="uploadDataProcessingContract"
                             accept="application/pdf"><br>
                    </div>
                    <vs-button color="warning" type="border" class="mr-3 w-full mt-2 mb-auto" size="small"
                               target="_blank"
                               @click="deleteContractFile"
                               v-if="contract_file"
                    >
                      Entfernen
                    </vs-button>


                  </div>
                </div>
              </div>
              <div class="vx-col sm:w-1/2 w-full mb-1">

                <vs-alert color="danger" icon="new_releases" class="mb-3" v-if="!isSelectedTypeAutomaticAccounting">
                    Bitte beachte, dass bei diesem Vertragstyp der Vertrag <b>nicht automatisch abgerechnet wird</b>. Du musst ihn weiterhin händisch abrechnen.
                    Dies ist nur für das Controlling und Pre-Calculating relevant.
                </vs-alert>

                <label>Vertragstyp:</label>
                <mg-select v-model="type_id" :options="types" v-validate="'required'" name="types"
                           placeholder="Vertragstyp auswählen" track-by="id" label="name"></mg-select>


                <br>
                <label>Automatische Aufgabenzuweisung:</label>
                <mg-select v-model="automatic_task_allocation_user_id" :options="users" name="automatic_task_allocation_user_id"
                           placeholder="Automatische Aufgabenzuweisung" track-by="id">

                  <template slot="option" slot-scope="props">
                    <div class="option__desc"><span class="option__title">{{ props.option.detail.first_name }} {{ props.option.detail.last_name }}</span></div>
                  </template>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc"><span class="option__title">{{ props.option.detail.first_name }} {{ props.option.detail.last_name }}</span></span>
                  </template>

                </mg-select>

                <div class="mt-5">
                  <label >Separater Rechnungskreislauf:</label><br>
                  <small style="color:red;">Bitte nur auswählen, wenn dieses Angebot in einer extra Rechnung abgerechnet werden soll. </small>

                  <mg-select v-model="separately_invoice_id" :options="separately_invoices" name="separately_invoice_id"
                             placeholder="Rechnungskreislauf wählen" track-by="id">

                    <template slot="option" slot-scope="props">
                      <div class="option__desc"><span class="option__title">{{ props.option.name }} </span></div>
                    </template>
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc"><span class="option__title">{{ props.option.name }} </span></span>
                    </template>

                  </mg-select>
                </div>

                <br>
                <vs-textarea label="Interner Kommentar" v-model="internal_comment"/>


                <mg-select v-model="offer_id" :options="offers" name="offer_id"
                           placeholder="Angebot zuordnen" track-by="id">

                  <template slot="option" slot-scope="props">
                    <div class="option__desc"><span class="option__title">#<span v-if="props.option.invoice_number ">{{ props.option.invoice_number }}</span> - {{ props.option.title }} </span></div>
                  </template>
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc"><span class="option__title">#<span v-if="props.option.invoice_number ">{{ props.option.invoice_number }}</span> - {{ props.option.title }} </span></span>
                  </template>

                </mg-select>

                <router-link  v-if="offer_id" :to="'/offers/'+offer_id+'/preview/'"  target="_blank" class="mt-3 d-block">
                             <span v-if="offer">Zum Angebot #{{ offer.invoice_number }} vom {{ offer.order_date }} <br>{{ offer.title }}</span>
                             <span v-if="!offer">Zum hinterlegten Angebot ></span>
                </router-link>
              </div>
            </div>
          </vx-card>
          <transition name="fade">
            <vx-card title="Vertragsdetails" class="mt-base" v-if="client_id">
              <div class="vx-row">
                <div class="vx-col sm:w-1/2 w-full mb-1">


                  <div class="vx-row mb-2">
                    <div class="vx-col w-full mb-0">
                      <label>Vertragsbeginn:</label><br>
                      <flat-pickr v-model="contract_start" :config="datePickerConfig"
                                  name="contract_start" v-validate="'required'"
                                  placeholder="Datum auswählen"></flat-pickr>
                      <br>
                      <small><span class="link" @click="setContractStartYesterday">Gestern</span>
                        |
                        <span class="link" @click.prevent="setContractStartToday">Heute</span> |
                        <span class="link" @click="setContractStartTomorrow">Morgen</span>
                      </small>
                      <span class="text-danger text-sm" v-if="errors.has('contract_start')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                  <div class="vx-row mb-2">
                    <div class="vx-col w-full mb-0">
                      <label>Vertragsende:</label><br>
                      <flat-pickr v-model="contract_end" :config="datePickerConfig"
                                  name="contract_end" v-validate="'required'"
                                  placeholder="Datum auswählen"></flat-pickr>
                      <br>
                      <small><span class="link"
                                   @click="setContractEnd">Vertragsende berechnen</span></small>
                      <span class="text-danger text-sm" v-if="errors.has('contract_end')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                </div>


                <div class="vx-col sm:w-1/2 w-full mb-1">

                  <div class="vx-row mb-2">
                    <div class="vx-col  w-full mb-0">
                      <label>Kündigungsfrist</label>
                      <mg-select v-model="period_of_notice"
                                 :options="contractOptions.period_of_notices"
                                 v-validate="'required'" name="period_of_notice" track-by="value"
                                 label="label"></mg-select>
                      <span class="text-danger text-sm" v-if="errors.has('period_of_notice')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                  <div class="vx-row mb-2">
                    <div class="vx-col  w-full mb-0">
                      <label>Automatische Vertragsverlängerung bei keiner fristgerechten
                        Kündigung</label>
                      <mg-select v-model="automatic_contract_extension"
                                 :options="contractOptions.automatic_contract_extensions"
                                 v-validate="'required'" name="automatic_contract_extension"
                                 track-by="value" label="label"></mg-select>
                      <span class="text-danger text-sm"
                            v-if="errors.has('automatic_contract_extension')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                  <div class="vx-row mb-2">
                    <div class="vx-col  w-full mb-0">
                      <label>Abrechnungsintervall</label>
                      <mg-select @select="checkChangesInBillingInterval"
                                 v-model="billing_interval"
                                 :options="contractOptions.billing_intervals"
                                 v-validate="'required'" name="billing_interval" track-by="value"
                                 label="label"></mg-select>
                      <span class="text-danger text-sm" v-if="errors.has('billing_interval')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>


                  <div class="vx-row mb-2">
                    <div class="vx-col  w-full mb-0">
                      <label>Zeitpunkt der Abrechnung</label>
                      <mg-select v-model="billing_point_in_time"
                                 :options="contractOptions.billing_point_in_times"
                                 v-validate="'required'" name="billing_point_in_time"
                                 track-by="value" label="label"></mg-select>
                      <span class="text-danger text-sm"
                            v-if="errors.has('billing_point_in_time')">Dieses Feld ist ein Pflichtfeld</span>
                    </div>
                  </div>

                </div>
              </div>


            </vx-card>
          </transition>
          <transition name="fade">
            <vx-card title="Vertragspositionen" class="mt-base" v-if="client_id">
              <div class="add-position">
                <vs-button @click="openArticleSearch(false)">Positionen hinzufügen</vs-button>
              </div>

              <draggable :list="contractItems" class="cursor-move">
                <vs-row class="table-content p-6 relative" v-for="(item, index) in contractItems">
                  <vs-col vs-w="9" class="pr-2">
                    <div class="vs-row">
                      <vs-col vs-w="6" class="pr-2 relative">

                        <div class="vs-row flex items-center mb-4 relative">

                          <vs-col vs-w="6" class="pr-4">
                            <vs-input label="Produkt-Nr." v-model="item.ordernumber" readonly class="w-full" />
                          </vs-col>
                          <vs-col vs-w="12" class="pt-4">
                            <span class="link" @click="openArticleSearch(index)" color="primary" type="filled">Anderes Produkt auswählen</span>
                          </vs-col>
                        </div>
                        <vs-input label="Produkt Bezeichnung" v-model="item.product_name" class="w-full" />
                        <span class="text-danger text-sm"
                              v-if="errors.has('item.product_name')">Dieses Feld ist ein Pflichtfeld</span>

                          <small>Contract Item ID: {{item.id}}</small>

                        <div class="licence-product mt-5" v-if="item.licence_product">

                          <h4>Lizenzprodukt</h4>

                          <label>Lizenzschlüssel</label><br>
                          <code>{{item.licence_product.license.id}}</code>
                          <b>Domain:</b> {{item.licence_product.license.domain}}<br>
                          <b>Produktname:</b> {{item.article.name}}
                          <vs-button color="dark" type="filled" icon="visibility" size="small" class="mt-2"
                                     :to="'/licence-manager/'+item.licence_product.license.id">Lizenz anzeigen</vs-button>

                        </div>


                        <div class="ssl-domain-settings mt-4" v-if="item.article && (item.article.article_type_id === 10 || item.article.article_type_id === 9 )">
                          <h3 v-if="item.article.article_type_id === 9 && (item.article_assignment_id=== 18 || item.article_assignment_id=== 31 || item.article_assignment_id === 67)">SSL-Zertifikats Einstellungen</h3>
                          <h3 v-if="item.article.article_type_id === 10">Domain Einstellungen</h3>
                          <vs-col vs-w="12" class="pr-4 mt-2" >
                            <vs-input label="Domain" v-model="item.domain" class="w-full"  />
                          </vs-col>
                          <vs-col vs-w="12" class="pr-4 mt-2" >
                            <label>Anbieter</label>
                            <mg-select :options="hostOptions"
                                       v-model="item.host"
                                       track-by="name"
                                       label="name">
                            </mg-select>
                          </vs-col>

                          <vs-col vs-w="12" class="pr-4 mt-2"  v-if="item.article.article_type_id === 9 && (item.article_assignment_id=== 18 || item.article_assignment_id=== 31 || item.article_assignment_id === 67)">
                              <vs-textarea label="Private Key:" v-model="item.ssl_certificate_private_key"  class="w-full" />
                          </vs-col>

                          <vs-col vs-w="6" class="pr-4 mt-2">
                            <vs-input label="Gültig bis" v-model="item.valid_to"  readonly class="w-full" />
                          </vs-col>
                        </div>

                        <div class="col-options">
                          <div class="col-options--button" @click="onClickProductDetails(index)">
                            <feather-icon icon="InfoIcon" class="w-5 h-5"/>
                          </div>
                        </div>

                      </vs-col>

                      <vs-col vs-w="6" class="pl-2">
                        <label class="vs-input--label">Beschreibung</label>
                        <vue-editor name="description" v-model="item.short_description" :editorToolbar="toolbar" data-vv-as="Beschreibung" />

                        <div class="d-block  mt-4">
                          <label class="vs-input--label">Wurde diese Vertragsposition an dem geplanten Abrechnungsdatum <b>abgerechnet</b>? </label><br>
                          <div class="last-statistic">

                            <div class="item" v-for="(plannedBillingDate, plannedBillingDateIndex) in item.planned_billing_dates" :key="plannedBillingDateIndex"
                                 v-bind:class="plannedBillingDate ? plannedBillingDate.className : ''">
                              <vx-tooltip :title="toolTipTitleFromplannedBilling(plannedBillingDate)"  position="top" html="true">
                                <span v-if="!plannedBillingDate.id">Für <b>{{ plannedBillingDateIndex|date }}</b> keine Abrechnung gefunden.</span>
                                <b v-else>{{ plannedBillingDateIndex|date }}</b>

                                <template slot="text" >
                                  <div v-html="toolTipTextFromplannedBilling(plannedBillingDate,plannedBillingDateIndex)">
                                  </div>
                                </template>
                              </vx-tooltip>

                            </div>


                          </div>
                        </div>

                        <div class="d-block  mt-4">
                          <b>Preisveränderung?</b><br>
                          <vs-row>
                            <vs-col vs-w="6" class="pr-2">
                              <vs-input label="Neuer Einzelpreis"
                                        v-model="item.unit_price_after_price_update"
                                        class="w-full mr-3"
                                        v-currency="{
                                            currency: 'EUR',
                                            locale: 'de',
                                            autoDecimalMode: false,
                                            decimalLength: 2,
                                            min: 0,
                              }"/>
                            </vs-col>
                            <vs-col vs-w="6">
                              <label>Gültig ab:</label><br>
                              <flat-pickr v-model="item.price_update_from" :config="datePickerConfig"
                                          name="price_update_from" v-validate="'required'"
                                          placeholder="Datum auswählen"></flat-pickr>
                            </vs-col>
                          </vs-row>


                        </div>

                      </vs-col>
                    </div>
                  </vs-col>

                  <vs-col vs-w="3" class="pl-2">

                    <label class="vs-input--label">Einzelpreis</label>
                    <vs-input v-model="item.unit_price" placeholder="Einzelpreis"
                              class="mb-2 w-full"
                              v-currency="{
                              currency: 'EUR',
                              locale: 'de',
                              autoDecimalMode: false,
                              decimalLength: 2,
                              min: 0,
                            }"
                    />

                    <div class="vs-row flex">
                      <vs-col vs-w="6" class="pr-4">
                        <label class="vs-input--label">Menge</label>

                        <vs-input-number color="success" @change="updateBillingInterval" v-model="item.amount" :step="0.5" class="mb-2"/>
                      </vs-col>
                      <vs-col vs-w="6">
                        <vs-select label="Einheit"  @change="updateBillingInterval" v-model="item.volume_unit" class="w-full">
                          <vs-select-item :key="item.value" :value="item.value" :text="item.label"
                                          v-for="(item, index) in unitOptions(item)"/>
                        </vs-select>

                          <small><i v-if="item.article.is_hour_unit">Stundensatz-Artikel</i></small>

                      </vs-col>
                      <div v-show="item.billingIntervalErrorProp" class="vs-alert con-icon"><i class="vs-icon notranslate icon-scale icon-alert material-icons null">error</i>Stimmt nicht mit dem  Abrechnungsintervall überein!</div>
                    </div>

                    <p class="sum_total mt-4 block text-right text-lg font-bold"> Gesamtpreis: {{itemSum(item)}} €</p>

                  </vs-col>

                  <div class="absolute -top-3 -right-3 bg-white shadow p-1 pb-0 border-r-2">
                    <i class="material-icons cursor-pointer text-danger hover:text-black" v-on:click="removeItemRow(index)">
                      close
                    </i>
                  </div>

                </vs-row>
              </draggable>

              <vs-row class="text-right">
                <vs-col vs-type="flex" vs-w="12" class="total-container text-right">

                  <div class="total">
                    <div class="period_total mt-4">
                      <div class="label">Nettobetrag je Abrechnungsperiode:</div>
                      <div class="sum"><span id="subtotal">{{total}}</span> <span
                          class="currencyShort"><span
                          class="currency_sign ">€</span></span></div>
                      <div class="clear"></div>
                    </div>
                    <div class="contract_total">
                      <div class="label">Nettobetrag je Vertragslaufzeit:</div>
                      <div class="sum"><span id="total">{{totalPeriodOfNotice}}</span> <span
                          class="currencyShort"><span
                          class="currency_sign ">€</span></span></div>
                      <div class="clear"></div>
                    </div>
                  </div>


                </vs-col>
              </vs-row>


            </vx-card>
          </transition>

          <vs-row class="mt-base">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="left" vs-w="6">
              <vs-button color="warning" type="border" class="mb-2" to="/contracts">Abbrechen</vs-button>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="right" vs-w="6">
              <vs-button class="mr-3 mb-2" :disabled="!validateForm" @click="updateEntry">Speichern
              </vs-button>
            </vs-col>
          </vs-row>

        </vs-row>
      </vs-col>

      <vs-col vs-w="3" class="box_activityfeed_container activity-list cardx">
        <h6 class="mb-0">Künftige Abrechungen:</h6><br>
        <vs-card>
          <div class="vx-row mb-4 mt-4">
            <div class="vx-col w-1/3">Kündigung möglich bis:</div>
            <div class="vx-col w-2/3">{{ contract.renew_date | moment("DD.MM.YYYY") }}
              <span v-if="contractDaysToNotice.days > 30">(In {{ contract.renew_date | daysBeetweenToday }} Tage)</span>
              <span v-if="contractDaysToNotice.days <= 30" class="text-red">(In {{ contract.renew_date | daysBeetweenToday }} Tage)</span>

            </div>
          </div>
        </vs-card>
        <vs-card>
          <div class="vx-row">
            <div class="vx-col w-1/3">Abrechnungs Datum:</div>
            <div class="vx-col w-3/3">
              <template v-for="billing in contract.billing_dates">
                <div class="billing-dates-list d-block">
                  {{ billing | moment("DD.MM.YYYY") }}
                  <span class="text-red">(In {{ billing | daysBeetweenToday }} Tage)</span>
                  <div class="inline-block manual-accounting-button" @click="runManualAccounting(billing)">
                    <vx-tooltip text="Möchtest du diesen kompletten Vertrag für dieses Abrechnungsdatum wiederholt abrechnen?" class="">
                     <feather-icon  icon="DollarSignIcon"  svg-classes="w-4 h-4 text-success" />
                    </vx-tooltip>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </vs-card>

        <history :histories="order_history" class="mt-4"></history>

        <div>
          <h6 class="mb-0 mt-8">Rechnungen:</h6><br>

          <vs-row class="body-container" v-if="ordersData.length >= 1">
            <vs-row class="activity-item" v-for="(tr, indextr) in ordersData">
              <vx-tooltip :text="formatDate(ordersData[indextr].order_date)">
                <div class="con-vs-chip vs-chip-null" style="color: rgba(0, 0, 0, 0.7);">
                    <span class="text-chip vs-chip--text">

                        <div class="h-2 w-2 rounded-full mr-2 bg-success"></div>
                        <span class="pl-2"> {{ ordersData[indextr].invoice_number }} </span>
                    </span>
                  <!---->
                </div>
              </vx-tooltip>

              <a :href="'/invoices/' + ordersData[indextr].id"  v-if="ordersData[indextr].order_status && ordersData[indextr].order_status.is_draft === 1">
                Zur Rechnung
              </a>
              <a :href="'/invoices/view/' + ordersData[indextr].id"  v-if="ordersData[indextr].order_status && ordersData[indextr].order_status.is_draft === 0">
                Rechnung ansehen
              </a>
              <a :href="'/invoices/' + ordersData[indextr].id"  v-if="!ordersData[indextr].order_status">
                Rechnung ansehen
              </a>

              <div class="ml-2" v-html="ordersData[indextr].order_status.label">{{ordersData[indextr].order_status.label}}</div>
              <vs-button color="warning" type="border" icon="picture_as_pdf" size="small"
                         :to="orderDocumentUrl(ordersData[indextr].file)"  v-if="ordersData[indextr].file"  class="ml-2"></vs-button>
            </vs-row>

          </vs-row>
          <vs-row class="body-container" v-if="ordersData.length == 0">
            Keine Rechnungen gefunden
          </vs-row>

        </div>



      </vs-col>
    </vs-row>

    <vs-popup title="Produkt auswählen" :active.sync="popupArticleSearch">
      <p>
        <mg-select :options="articles"
                   track-by="id"
                   label="name"
                   @select="onSelectArticle">

        </mg-select>
      </p>
    </vs-popup>

    <product-detail-modal :product-id="selectedProductId" :active.sync="productDetailModalOpen"
                          @close="productDetailModalOpen=false"></product-detail-modal>
  </div>


</template>

<script>
import ApiService from "../../../api/index";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import draggable from 'vuedraggable'
import PriceHelper from '@/mixins/helper/price.helper';
import MgSelect from "../../../components/mg-select/MgSelect";
import ContractOptions from "./contract-options";
import History from "../../../components/history/History";
import * as qs from "qs";
import ProductDetailModal from "../../../components/product/ProductDetailModal";
import SeparatelyInvoicesSelector from "../../../components/search/SeparatelyInvoicesSelector";
import staticOptions from "@/mixins/static/options";


export default {
  components: {
    ProductDetailModal,
    MgSelect,
    flatPickr,
    draggable,
    History,
    SeparatelyInvoicesSelector
  },
  name: "contract-add",
  data() {
    return {
      entryId: null,
      selected: [],
      log: [],
      client_id: null,
      clients: [],
      contract: {},
      contract_end: "",
      contract_start: "",
      datePickerConfig: {},
      internal_comment: "",
      offer_id: null,
      offer: [],
      offers: [],
      contract_number: null,
      status: null,
      popupArticleSearch: false,
      contractItems: [],
      number2: 0,
      value1: "",
      short_description: null,
      amount: 0,
      itemVolumeUnit: null,
      type_id: null,
      automatic_task_allocation_user_id: null,
      separately_invoice_id: null,
      separately_invoices: [],
      types: [],
      period_of_notice: null,
      billing_interval: null,
      billing_point_in_time: null,
      automatic_contract_extension: null,
      contractOptions: {},
      articles: [],
      articleSearchItemIndex: null,
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}],
        [{'color': []}],
      ],
      order_history: [],
      ordersData: [],
      contract_file: null,
      productDetailModalOpen: false,
      selectedProductId: null,
      users: [],
      billingIntervalErrorProp: false,
      domain: '',
      hostOptions:[
        {name: 'mittwald'},
        {name: 'hetzner'},
        {name: 'namecheap'}
      ],
    }
  },
  created() {
    this.loadData()
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.automatic_contract_extension !== '' && this.billing_point_in_time !== '' && this.firstname !== '' && this.lastname !== '';
    },
    total() {
      const total = this.contractItems.reduce(
          (acc, item) => acc + PriceHelper.parseCommaFloat(item.unit_price) * PriceHelper.parseCommaFloat(item.amount),
          0
      );

      return PriceHelper.floatPointToComma(total);
    },

    totalPeriodOfNotice() {
      const total = this.contractItems.reduce(
          (acc, item) => acc + PriceHelper.parseCommaFloat(item.unit_price) * PriceHelper.parseCommaFloat(item.amount),
          0
      );
      return PriceHelper.floatPointToComma(total * this.automatic_contract_extension / 12 * this.billingIntervalToMultiplier);
    },
    parsedContractItems() {
      let newItems = [], clonedItems = JSON.parse(JSON.stringify(this.contractItems))

      clonedItems.forEach(function (item) {
        item.unit_price = PriceHelper.parseCommaFloat(item.unit_price)
        item.unit_price_after_price_update = PriceHelper.parseCommaFloat(item.unit_price_after_price_update)
        newItems.push(item)
      })
      return newItems
    },
    billingIntervalToMultiplier() {
      if (this.billing_interval === "2j") {
        return 0.5;
      } else if (this.billing_interval === "1j") {
        return 1;
      } else if (this.billing_interval === "6m") {
        return 2;
      } else if (this.billing_interval === "3m") {
        return 4;
      } else if (this.billing_interval === "1m") {
        return 12;
      } else if (this.billing_interval === "1me") {
        return 12;
      } else {
        return 12;
      }
    },
    contractDaysToNotice() {
      let days = this.$options.filters.daysBeetweenToday(this.contract.renew_date)
      let daysToNotice = {
        days: days,
        className: (days > 30) ? "color-red" : "color-green"
      };

      return daysToNotice;
    },
      isSelectedTypeAutomaticAccounting() {
          const type = this.types.find(item => item.id === this.type_id);
          console.log(type)
          if (type.automatic_accounting_active === 1){
              return true;
          }
          return false;

      },
    orderHistoryRequestParams() {
      return {
        order: {
          'field': 'created_at',
          'direction': 'DESC'
        },
        filter: [{
          field: 'object_id',
          value: this.$route.params.id,
          expression: 'exact',
        },
          {
            field: 'object_type',
            value: "App\\\\Models\\\\Contract\\\\Contract"
          }]

      }
    },

  },
  methods: {
    loadData(){

      this.contractOptions = ContractOptions;
      this.entryId = this.$route.params.id;

      if (!this.entryId) {
        this.$router.push('/contracts')
        return;
      }

      this.$vs.loading()

      ApiService.get('articles').then(response => {
        if (response.status !== 200) {
          return;
        }
        this.articles = response.data.result;

      })

      ApiService.get('contracts/' + this.entryId).then(response => {
        let entryData = response.data.result;

        this.contract = entryData;

        this.fetchHistory(this.$route.params.id)

        this.client_id = parseInt(entryData.client_id);
        this.contract_end = entryData.contract_end;
        this.contract_start = entryData.contract_start;
        this.internal_comment = entryData.internal_comment;
        this.status = entryData.status;
        this.contract_number = entryData.contract_number;
        this.contract_file = entryData.contract_file;

        this.automatic_contract_extension = parseInt(entryData.automatic_contract_extension);
        this.billing_point_in_time = entryData.billing_point_in_time;
        this.billing_interval = entryData.billing_interval;
        this.period_of_notice = entryData.period_of_notice;
        this.type_id = parseInt(entryData.type_id);
        this.itemVolumeUnit = entryData.itemVolumeUnit;
        this.automatic_task_allocation_user_id = entryData.automatic_task_allocation_user_id;

        this.offer_id = entryData.offer_id;
        this.offer = entryData.offer;

        this.separately_invoice_id = entryData.separately_invoice_id;
        this.contractItems = entryData.items;

        for(let i = 0;i < this.contractItems.length; i++) {
          if(this.contractItems[i].article) {
            this.contractItems[i].ordernumber = this.contractItems[i].article.ordernumber;
          }
          this.contractItems[i].unit_price = PriceHelper.floatPointToComma(this.contractItems[i].unit_price);
          this.contractItems[i].unit_price_after_price_update = PriceHelper.floatPointToComma(this.contractItems[i].unit_price_after_price_update);
        }


        let clientsPromise = ApiService.get('clients');

        let ContractTypesPromise = ApiService.get('contract_types');

        let SeparatelyInvoicesPromise = ApiService.get('separately_invoices/'+this.client_id);

        Promise.all([clientsPromise, ContractTypesPromise, SeparatelyInvoicesPromise]).then(result => {
          if (result[0].status === 200) {
            this.clients = result[0].data.result;
          }
          if (result[1].status === 200) {
            this.types = result[1].data.result;
          }

          if (result[2].status === 200) {
            this.separately_invoices = result[2].data.result;
          }
          this.$vs.loading.close()

        });

        this.fetchOrdersData()
        this.fetchUsers()
        this.checkBillingInterval()
        this.fetchOffers()

      }).catch(response => {
        this.$router.push('/contracts');
        this.$vs.loading.close()
      })

    },
    fetchOffers() {

      let queryParams = {
        filter: [
          {
            field: 'type.invoice_type',
            expression: 'exact',
            value: 'offer'
          },
          {
            field: 'client_id',
            expression: 'exact',
            value: this.client_id
          },
          {
            expression: 'group',
            filter: [
              {
                field: 'status',
                expression: 'exact',
                value: '16',
                operator: 'or',
              },
              {
                field: 'status',
                expression: 'exact',
                value: '18',
                operator: 'or',
              },
              {
                field: 'status',
                expression: 'exact',
                value: '19',
                operator: 'or',
              }
            ]
          }
        ]
      };

      return ApiService.get('orders', {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.offers = response.data.result;
      })
    },
    onClickProductDetails(i) {
      this.selectedProductId = this.contractItems[i].article.id;
      this.productDetailModalOpen = true;
    },
    orderDocumentUrl(filename) {
      if(!filename) {
        return  null;
      }
      return `${process.env.VUE_APP_API_URL}document/order/invoice/${filename}`;
    },
    fetchOrdersData(){

      let queryParams = {
        order: [{
          field: 'order_date',
          direction: 'desc',
        }],
        filter: [
          {
            field: 'order_type_id',
            value: 7,
            expression: 'exact'
          }]
      };

      if(this.contractItems.length === 1) {
        queryParams.filter.push({
          field: 'items.contract_item_id',
          value: this.contractItems[0].id,
          expression: 'exact'
        })
      } else {

        var filterGroup = {
          expression: 'group',
          filter: []
        };

        for (let i = 0; i < this.contractItems.length; i++) {
          if(this.contractItems[i].id) {
            filterGroup.filter.push({
              field: '          field: \'items.contract_item_id\',\n.contract_item_id',
              value: this.contractItems[i].id,
              expression: 'exact',
              operator: 'or',
            })
          }
        }

        queryParams.filter.push(filterGroup)
      }

      ApiService.get('orders', {
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then(response => {
        this.ordersData = response.data.result;
      })



    },


    itemSum(item) {
      return PriceHelper.floatPointToComma(PriceHelper.parseCommaFloat(item.unit_price) * PriceHelper.parseCommaFloat(item.amount));
    },
    setContractStartYesterday() {
      this.contract_start = moment().subtract(1, 'day').format('YYYY-MM-DD');
    },
    setContractStartTomorrow() {
      this.contract_start = moment().add(1, 'day').format('YYYY-MM-DD');
    },
    setContractStartToday() {
      this.contract_start = moment().format('YYYY-MM-DD');
    },
    setContractEnd() {
      this.contract_end = moment(new Date(this.contract_start)).add(this.automatic_contract_extension, 'month').format('YYYY-MM-DD');
    },
    updateEntry() {
      let me = this;

      if (!this.validateForm) return

      me.$vs.loading()

      const payload = {
        client_id: this.client_id,
        contract_start: this.contract_start,
        contract_end: this.contract_end,
        automatic_contract_extension: this.automatic_contract_extension,
        status: this.status,
        billing_interval: this.billing_interval,
        billing_point_in_time: this.billing_point_in_time,
        period_of_notice: this.period_of_notice,
        offer_id: this.offer_id,
        internal_comment: this.internal_comment,
        type_id: this.type_id,
        automatic_task_allocation_user_id: this.automatic_task_allocation_user_id,
        separately_invoice_id: this.separately_invoice_id,
        items: this.parsedContractItems,
      }

      ApiService.put('contracts/' + this.entryId, payload).then((response) => {

        me.$vs.loading.close()

        me.$vs.notify({
          title: 'Erfolgreich',
          text: 'Ihr Account wurde erfolgreich angelegt',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })

        me.$router.push('/contracts');

      }).catch((response) => {

        me.$vs.loading.close()

        me.$vs.notify({
          title: 'Error',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      });

    },
    addItemRow() {
      this.contractItems.push({
        "id": null,
        "contract_id": 1,
        "amount": 1,
        "volume_unit": 1,
        "product_name": "",
        "short_description": "",
        "unit_price": 1,
        "article_assignment_id": null
      });
    },
    removeItemRow: function (index) {
      this.contractItems.splice(index, 1);
    },
    openArticleSearch(index) {
      if(index === false) {
        this.articleSearchItemIndex = null;
        return this.popupArticleSearch = true;
      }

      this.articleSearchItemIndex = index;
      this.popupArticleSearch = true;
    },
    onSelectArticle(article) {
      if(this.articleSearchItemIndex == null) {
        this.contractItems.push({
          article_assignment_id: article.id,
          article: article,
          product_name: article.name,
          unit_price: PriceHelper.floatPointToComma(article.price),
          volume_unit: parseInt(article.unit),
          short_description: article.description,
          ordernumber: article.ordernumber,
          amount: 1
        });

        return this.popupArticleSearch = false;
      }

      if(this.contractItems[this.articleSearchItemIndex]) {
        this.contractItems[this.articleSearchItemIndex].article_assignment_id = article.id;
        this.contractItems[this.articleSearchItemIndex].product_name = article.name;
        this.contractItems[this.articleSearchItemIndex].unit_price = PriceHelper.floatPointToComma(article.price);
        this.contractItems[this.articleSearchItemIndex].volume_unit = parseInt(article.unit);
        this.contractItems[this.articleSearchItemIndex].short_description = article.description;
        this.contractItems[this.articleSearchItemIndex].ordernumber = article.ordernumber;
        this.contractItems[this.articleSearchItemIndex].amount = 1;
        this.contractItems.push();
      }

      this.popupArticleSearch = false;
    },
    fetchHistory(id){
      ApiService.get(`activities`, {
        params: this.orderHistoryRequestParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
      }).then((response) => {
        if (response.data.status === 'success') {
          this.order_history = response.data.result.result;
        }
      });


    },
    formatDate(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
    fetchUsers() {

      let queryParams = {

      };

      this.$vs.loading()
      ApiService.get('users',{
        params: queryParams,
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})}).then(response => {
        this.users = response.data.result;

      }).catch(response => {
      })
    },
    checkBillingInterval () {
      for (let i = 0; i < this.contractItems.length; i++) {

        this.$set(this.contractItems[i], 'billingIntervalErrorProp', false)

        if (this.billing_interval === "1j" && (this.contractItems[i].amount != 1 || this.contractItems[i].volume_unit !== 3)) {
          this.contractItems[i].billingIntervalErrorProp = true;

        } else if (this.billing_interval === "2j" && (this.contractItems[i].amount != 2 || this.contractItems[i].volume_unit !== 3)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "3j" && (this.contractItems[i].amount != 3 || this.contractItems[i].volume_unit !== 3)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "4j" && (this.contractItems[i].amount != 4 || this.contractItems[i].volume_unit !== 3)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "6m" && (this.contractItems[i].amount != 6 || this.contractItems[i].volume_unit !== 2)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "3m" && (this.contractItems[i].amount != 3 || this.contractItems[i].volume_unit !== 2)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "1m" && (this.contractItems[i].amount != 1 || this.contractItems[i].volume_unit !== 2)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        } else if (this.billing_interval === "1me" && (this.contractItems[i].amount != 1 || this.contractItems[i].volume_unit !== 2)) {
          this.contractItems[i].billingIntervalErrorProp = true;
        }
      }
    },
    updateBillingInterval() {
      this.checkBillingInterval();
    },
    checkChangesInBillingInterval(item) {
      this.billing_interval = item.value
      this.checkBillingInterval();
    },
    toolTipTitleFromplannedBilling(plannedBillingDate){
      if (plannedBillingDate.id) {
        return "Abrechnung gefunden"
      }
      if (!plannedBillingDate.id) {
        return "Abrechnung nicht gefunden"
      }
    },
    toolTipTextFromplannedBilling(accounting,plannedBillingDate){

      var invoiceDate = accounting.order ? this.$options.filters.date(accounting.order.order_date) : "NOT FOUND!!!!!"
      var invoiceNumber = accounting.order ? accounting.order.invoice_number : "NOT FOUND!!!!!"
      var accounting_start = accounting.accounting_start != null ?  this.$options.filters.date(accounting.accounting_start) : "NOT FOUND!!!!!"
      var accounting_end =  accounting.accounting_end != null ?  this.$options.filters.date(accounting.accounting_end) : "NOT FOUND!!!!!"
      var accountingAmount = accounting.amount
      var accountingUnitPrice = accounting.unit_price
      var accountingSumPrice = accounting.unit_price * accounting.amount
      var accountingcreatedAt = accounting.created_at ? this.$options.filters.dateTime(accounting.created_at) : "undefined"

      if(accounting.created_at && accounting.created_at.startsWith(plannedBillingDate)) {
        var accountingcreatedPlannedDateInfo = "Erstelldatuminfo: <p style='color:green'>Erstelldatum entspricht dem geplanten Abrechnungsdatum.</p>"
      } else if (accounting.created_at) {
        var accountingcreatedPlannedDateInfo = "Erstelldatuminfo: <p style='color:orangered'>Erstelldatum entspricht <b>nicht</b> dem geplanten Abrechnungsdatum. Bedeutet es wurde ggf. händisch angesteuert.</p>"
      } else {
        var accountingcreatedPlannedDateInfo = "<p style='color:red'>Überhaupt keine Abrechnung gefunden!!!!</p>"
      }

      return      "Rechnungdatum: <b>"+ invoiceDate + "</b><br>"+
                  "Rechnungnummer: <b>"+ invoiceNumber + "</b><br>"+
                  "Abrechnungszeitraum Beginn: <b>"+ accounting_start + "</b><br>"+
                  "Abrechnungszeitraum Ende: <b>"+ accounting_end + "</b><br>"+
                  "abgerechnete Menge: <b>"+ accountingAmount + "</b><br>"+
                  "Einzelpreis: <b>"+ accountingUnitPrice + "</b><br>"+
                  "Summe der Position: <b>"+ accountingSumPrice + "</b><br>"+
                  "Abrechnungsposition erstellt am: <b>"+ accountingcreatedAt + "</b><br>"+
                  accountingcreatedPlannedDateInfo + "<br>"
    },
    runManualAccounting(billingDate){
      console.log(billingDate)

      var payload = {
        "contractId": this.entryId,
        "billing_date": billingDate
      }
      this.$vs.loading()
      ApiService.post('contracts/billing-date-accounting', payload).then((response) => {

        this.$vs.loading.close()

        this.$vs.notify({
          title: 'Erfolgreich',
          text: 'Ihre Abrechnung wurde händisch vorgenommen',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })

       this.loadData()

      }).catch((response) => {

        this.$vs.loading.close()

        this.$vs.notify({
          title: 'Error',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      });


    },
      unitOptions(item) {
          if(item.article.is_hour_unit != undefined && item.article.is_hour_unit) {
              return staticOptions.units.filter(unit => unit.hour_unit);
          }

          return staticOptions.units;
      },
    deleteContractFile() {
      this.$vs.loading();

      let payload = {
        "contract_file": ""
      };

      //delete payload.customer_number;

      ApiService.put('contracts/' + this.$route.params.id, payload)
          .then((response) => {
            if (response.data.status === 'success') {
              this.loadData();
              return this.$vs.notify({
                title: 'Erfolgreich',
                text: 'Die Datei wurde erfolgreich gelöscht',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
              })
            }

            this.$vs.notify({
              title: 'Fehlgeschlagen',
              text: 'Der Vertrag konnte nicht bearbeitet werden.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
          .catch((error) => {
            this.$vs.notify({
              title: 'Fehlgeschlagen',
              text: 'Der Vertrag konnte nicht bearbeitet werden.',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })

    },
    uploadDataProcessingContract(event) {
      this.$vs.loading();

      const files = event.target.files;
      const formData = new FormData();

      formData.append('file', files[0]);

      const url = `contracts/${this.$route.params.id}/upload/data-processing-contract`;

      ApiService.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        document.getElementById("contractUpload").value = "";
        if (response.status === 200) {
          this.loadData();

          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Datei wurde erfolgreich hochgeladen.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        } else {
          this.$vs.notify({
            title: 'Fehlgeschlagen',
            text: 'Die Datei konnte nicht hochgeladen werden.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })

          this.$vs.loading.close();
        }
      }).catch(error => {
        document.getElementById("contractUpload").value = "";
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Datei konnte nicht hochgeladen werden.',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

        this.$vs.loading.close();
      })
    },
    openLicence(id){
      this.$router.push('/licence-manager/'+ id +'/')
    }
  },

  filters: {
    date(value) {
      return moment(value).format('DD.MM.YYYY');
    },
    dateTime(value) {
      return moment(value).format('DD.MM.YYYY HH:mm') + ' Uhr';
    },
    currency(value) {
      return value.toFixed(2);
    },
    daysBeetweenToday: function (value) {
      if (!value) return ''
      let now = moment();
      let end = moment(value.toString());
      let duration = moment.duration(end.diff(now));
      return duration.asDays().toFixed(0)
    }
  }
}
</script>

<style lang="scss">
.contract-detail {
  .quillWrapper {
    .ql-editor {
      height: 5rem;
      min-height: 5rem;
    }
  }
}
.vs-alert {
  background: #fbe4e1;
  width: 100%;
  color: #e24733;
  border-radius: 4px;
  border-width: 1px;
  margin-top: 10px;
}

.last-statistic {
  display: inline-block;
  margin-left:10px;
}

.last-statistic .item {
  background: #d4d4d4;
  /* height: 19px; */
  /* width: 5px; */
  border-radius: 5px;
  display: inline-block;
  margin-right: 3px;
  padding: 5px 10px;
  font-size: 10px;
  margin-bottom:5px
}

.last-statistic .item.success {
  background: #44ff00;
}
.last-statistic .item.failure {
  background: #ff0000;
  color:#fff;
}
.last-statistic .item.uncompleted {
  background: #bbb50d;
  color:#fff;
}
.vs-tooltip {
  max-width: 500px;
}

.billing-dates-list .manual-accounting-button {
  display: none!important;
}

.billing-dates-list:hover .manual-accounting-button {
  display: inline-block!important;
}
.manual-accounting-button:hover {
  cursor: pointer;
}


.licence-product {
  background: #ffffff;
  border: 1px solid #a3a3a3;
  border-radius: 7px;
  padding: 10px;

  -webkit-box-shadow: 5px 6px 8px 0px rgba(0,0,0,0.19);
  -moz-box-shadow: 5px 6px 8px 0px rgba(0,0,0,0.19);
  box-shadow: 5px 6px 8px 0px rgba(0,0,0,0.19);

  code {
    padding: 10px;
    margin: 0 0 9px 0;
    display: block;
    border-radius: 5px;
    border: 1px solid #c7c7c7;
  }


  h4 {
    margin-bottom: 8px;
  }
}


</style>

