const ContractOptions = {
  itemVolumeUnits: [
    {value: 1, label: 'Stk.' },
    {value: 2, label: 'Monat' },
    {value: 3, label: 'Jahr' },
    {value: 4, label: 'Stunde'},
    {value: 5, label: 'MT'},
  ],
  period_of_notices: [
    {value: "1j", label: '1 Jahr' },
    {value: "6m", label: '6 Monate' },
    {value: "3m", label: '3 Monate' },
    {value: "4m", label: '4 Monate' },
    {value: "4w", label: '1 Monat'},
    {value: "2w", label: '2 Wochen' },
    {value: "1w", label: '1 Woche' },
    {value: "0t", label: 'Keine K�ndigungsfrist' },
  ],
  billing_intervals: [
    {value: "1j", label: 'Jedes Jahr' },
    {value: "2j", label: 'Jede 2 Jahre' },
    {value: "3j", label: 'Jede 3 Jahre' },
    {value: "4j", label: 'Jede 4 Jahre' },
    {value: "6m", label: 'Jede 6 Monate' },
    {value: "3m", label: 'Jede 3 Monate' },
    {value: "1m", label: 'Jeden Monat'},
    {value: "1me", label: 'Jeden Monat am letzten Tag des Vor-Monats' },
  ],
  billing_point_in_times: [
    {value: "before", label: 'Vor dem Abrechnungszeitpunkt'},
    {value: "after", label: 'Nach dem Abrechnungszeitpunkt' }
  ],
  automatic_contract_extensions: [
    {value: 12, label: '1 Jahr'},
    {value: 24, label: '2 Jahre'},
    {value: 6, label: '6 Monate'},
    {value: 3, label: '3 Monate'},
    {value: 1, label: '1 Monat'},
  ],
};

export default ContractOptions;
