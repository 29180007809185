<template>
    <div class="boolean-icon">
        <feather-icon v-if="!value" icon="SlashIcon" svg-classes="boolean-icon__icon text-danger" />
        <feather-icon v-if="value" icon="CheckCircleIcon" svg-classes="boolean-icon__icon text-success" />
    </div>
</template>

<script>
    export default {
        name: "BooleanIcon",
        props: {
            value: {
                type: [Boolean, Number],
                default: false
            }
        }
    }
</script>

<style lang="scss">

    .boolean-icon {

    }

    .boolean-icon__icon {
        height: 1.4rem;
    }

</style>